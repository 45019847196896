import { Fragment, React, useContext, useState } from "react";
import { Context } from "../Context/Context";
import axios from "axios";


import "./setting.css";
function Setting() {
  const { user } = useContext(Context);
  const [username, setUsername] = useState(user.username);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [file, setFile] = useState(null);
  const [email, setEmail] = useState(user.email);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Password and Confrim password did not matched!!!");
      return;
    }

    const updatedUser = {
      userId: user._id,
      username,
      email,
      password,
    };

    if (file) {
      const data = new FormData();
      data.append("file", file);
      data.append("upload_preset", "osgvldv1");
      try {
        const response = await axios.post(
          "https://api.cloudinary.com/v1_1/dp9xuxsug/image/upload",
          data
        );
        updatedUser.profilePic = response.data["secure_url"];
      } catch (error) {
        alert(error);
        return;
      }
    }

    try {
      const authtoken = localStorage.getItem("auth-token");
      axios
        .put(
          "https://api-anlakshya.onrender.com/api/users/" + user._id,
          updatedUser,
          {
            headers: {
              "auth-token": authtoken,
              "x-window-location" : "anucodes.com"
            },
          }
        )
        .then(() => {
          setSuccess(true);
        });
    } catch (error) {
      alert(error);
    }
  };

  const showPassword = () => {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return (
    <div className="settings">
      <div className="settingsWrapper">
        <form className="settingsForm" onSubmit={handleSubmit}>
          <span className="settingsUpdateTitle">Update Your Account</span>
          <label htmlFor="fileInput">
            <div className="profileIcon">
              {user.profilePic ? (
                <img
                  src={user.profilePic}
                  className="profilePic"
                  width="60px"
                  height="60px"
                  alt="profilePic"
                  onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "";
                  e.target.alt = "";
                  e.target.style.display = "none";
                }}
                ></img>
              ) : (
                <i className="writeIcon fa-solid fa-plus"></i>
              )}
            </div>
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={(e) => setFile(e.target.files[0])}
            />

            {file && (
              <Fragment>
                <img
                  className="writeImg"
                  src={URL.createObjectURL(file)}
                  alt=""
                  onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "";
                  e.target.alt = "";
                  e.target.style.display = "none";
                }}
                />
                <i
                  className="deleteIcon fa-solid fa-trash"
                  onClick={() => {
                    setFile(null);
                  }}
                ></i>
              </Fragment>
            )}
          </label>

          <label>Username</label>
          <input
            type="text"
            value={user.username}
            onChange={(e) => setUsername(e.target.value)}
            readOnly
          />
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label>Password</label>
          <input
            type="password"
            placeholder="Update Your Password"
            onChange={(e) => setPassword(e.target.value)}
            id="password"
            required
            minLength={5}
          ></input>
          <label>Confirm Password</label>
          <input
            type="password"
            placeholder="Confirm Your Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            id="password"
            required
            minLength={5}
          ></input>
          <label>
            <input type="checkbox" onClick={showPassword} /> Show Password{" "}
          </label>
          <button className="settingsSubmit" type="submit">
            Update
          </button>
          {success && (
            <span style={{ color: "red", textAlign: "center" }}>
              Profile has been updated...
            </span>
          )}
        </form>
      </div>
    </div>
  );
}

export default Setting;
