import React, { useState } from "react";
import Footer from "../Footer/Footer";
import AddBlog from "../Components/AddBlogs/AddBlogs";
import QNAFileUpload from "../Components/QNA/QNAFileUpload/QNAFileUpload";
import "./write.css";
function Write() {
  const [selectedTab, setSelectedTab] = useState("QNA"); // Default to QNA

  const handleTabChange = (tabName) => {
    setSelectedTab(tabName);
  };

  return (
    <>
      <div className="container write-container">
        <div className="write-tab">
          <button className={selectedTab === "AddBlogs" ? "active" : ""} onClick={() => handleTabChange("AddBlogs")}>Add Blogs</button>
          <button className={selectedTab === "QNA" ? "active" : ""} onClick={() => handleTabChange("QNA")}>Questions / Answer</button>
        </div>
        {selectedTab === "AddBlogs" && <AddBlog />}
        {selectedTab === "QNA" && <QNAFileUpload />}
        
      </div>

      <Footer />
    </>
  );
}

export default Write;
