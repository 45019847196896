import axios from "axios";
import React, { Fragment, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import "./singlePost.css";
import { Context } from "../Context/Context";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";

function SinglePost(props) {
  const location = useLocation();
  const path = location.pathname.slice(-24);
  const headers = {
    "x-window-location": "anucodes.com",
  };

  const [post, setPost] = useState({});

  const { user } = useContext(Context);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [linkTitle, setLinkTitle] = useState("");
  const [link, setLink] = useState("");
  const [updateMode, setUpdateMode] = useState(false);
  const [load, setLoad] = useState(true);
  const [image, setImage] = useState("");
  const [cateogry, setCategory] = useState("");

  useEffect(() => {
    setUpdateMode(false);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" }); // To scroll to the Top of window
    const getPost = async () => {
      setLoad(true);
      props.setProgress(20);
      const API_KEY = process.env.REACT_APP_API_KEY;
      const res = await axios.get(
        "https://api-anlakshya.onrender.com/api/posts/" +
        path +
        "?api=" +
        API_KEY,
        { headers }
      );

      // Set Title and description for Search Engine
      document.title = res.data.title;
      var div = document.createElement("div");
      div.innerHTML = res.data.desc;
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          div.innerText.split(" ").slice(0, 40).join(" ")
        );

      props.setProgress(60);
      setPost(res.data);
      setTitle(res.data.title);
      setDesc(res.data.desc);
      setLink(res.data.link);
      setLinkTitle(res.data.linkTitle);
      setImage(res.data.photo);
      setCategory(res.data.categories);
      props.setProgress(100);
      setLoad(false);
    };
    getPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const handleDelete = async () => {
    let authtoken = localStorage.getItem("auth-token");
    const headers = {
      "auth-token": authtoken,
      "x-window-location": "anucodes.com",
    };
    const data = {
      username: user.username,
    };

    try {
      await axios.delete(
        "https://api-anlakshya.onrender.com/api/posts/" + path,
        { headers, data }
      );
      alert("Blog has been Deleted successfully");
      // TO redirect to Home page
      window.location.replace("/");
    } catch (err) {
      alert(err);
    }
  };

  const Revalidate = async () => {
    try {
      const anucodesResponse = await axios.get(
        `https://www.anucodes.in/api/revalidate?secret=HiAnuragKumar&&slug=${title.replace(
          /[^a-zA-Z0-9]+/gi,
          "-"
        )}`
      );
      console.log(anucodesResponse);
    } catch (err) {
      alert(err);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoad(true);
      let authtoken = localStorage.getItem("auth-token");
      await axios.put(
        "https://api-anlakshya.onrender.com/api/posts/" + path,
        {
          username: user.username,
          slug: title.replace(/[^a-zA-Z0-9]+/gi, "-"),
          title: title,
          desc: desc,
          link: link,
          linkTitle: linkTitle,
          photo: image,
          categories: cateogry,
        },
        {
          headers: {
            "auth-token": authtoken,
            "x-window-location": "anucodes.com",
          },
        }
      );
      setLoad(false);
      Revalidate();
      alert("Blog has been updated & revalidated successfully");
      setUpdateMode(false);
    } catch (err) {
      alert(err);
    }
  };

  // Quill Editor Toolbar

  const modules = useMemo(
    () => ({
      syntax: { highlight: (text) => hljs.highlightAuto(text).value },
      toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
        ["link", "image", "video"],
        ["clean"],
      ],
    }),
    []
  );

  return (
    <div className="singlePost">
      {load ? (
        <div
          className="spinner-border text-primary loadingIcon h-500"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <div className="singlePostWrapper">
          {!updateMode ? (
            <span className="text-center">
              <b>{post.categories.toUpperCase()}</b>
            </span>
          ) : (
            <input
              type="text"
              className="singlePostTitleInput"
              value={cateogry}
              placeholder="Category"
              onChange={(e) => setCategory(e.target.value)}
            />
          )}
          {!updateMode ? (
            post.photo && (
              <img
                className="singlePostImg"
                src={post?.photo}
                alt={post.title}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "";
                  e.target.alt = "";
                  e.target.style.display = "none";
                }}
              />
            )
          ) : (
            <input
              type="text"
              className="singlePostTitleInput"
              placeholder={post.photo}
              value={image}
              onChange={(e) => setImage(e.target.value)}
            />
          )}

          {updateMode ? (
            <input
              type="text"
              value={title}
              className="singlePostTitleInput"
              autoFocus
              onChange={(e) => setTitle(e.target.value)}
            ></input>
          ) : (
            <h1 className="singlePostTitle">
              {title}

              {(post.username === user?.username || user?.role === "admin") && (
                <div className="singlePostEdit">
                  <i
                    className="singlePostIcon fa-solid fa-pen-to-square"
                    onClick={() => setUpdateMode(true)}
                  ></i>
                  <i
                    className="singlePostIcon fa-solid fa-trash"
                    onClick={() => {
                      confirmAlert({
                        message: "Do you really want to delete this post ?",
                        buttons: [
                          {
                            label: "Yes",
                            onClick: () => handleDelete(),
                          },
                          {
                            label: "No",
                          },
                        ],
                      });
                    }}
                  ></i>
                </div>
              )}
            </h1>
          )}

          <div className="singlePostInfo">
            <span className="singlePostAuthor">
              <Link
                style={{ textDecoration: "none", color: "inherit" }}
                to={`/post/?user=${post.username}`}
              >
                {post.profilePic && (
                  <img
                    src={post.profilePic}
                    className="profilePic"
                    width={"40px"}
                    height={"40px"}
                    alt="writter"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "";
                      e.target.alt = "";
                      e.target.style.display = "none";
                    }}
                  />
                )}
                <b style={{ marginLeft: "10px" }}>
                  {post.username.charAt(0).toUpperCase() +
                    post.username.slice(1)}
                </b>
              </Link>
            </span>

            <span className="singlePostDate">
              {new Date(post?.createdAt)?.toDateString()}
            </span>
          </div>

          {updateMode ? (
            <Fragment>
              <ReactQuill
                className="quillEditor"
                theme="snow"
                modules={modules}
                placeholder="Tell your story..."
                value={desc}
                onChange={setDesc}
              />
            </Fragment>
          ) : (
            <div className="ql-snow">
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: desc }}
                onCopy={(e) => {
                  e.preventDefault();
                }}
              ></div>
            </div>
          )}

          <br></br>
          <br></br>

          {updateMode && (
            <input
              type="text"
              value={linkTitle}
              className="singlePostLinkInput  mt-50"
              placeholder="Enter the Heading for Link"
              onChange={(e) => setLinkTitle(e.target.value)}
              style={{ marginTop: "20px" }}
            />
          )}

          {updateMode ? (
            <input
              type="text"
              value={link}
              className="singlePostLinkInput"
              placeholder="Please Paste the link here"
              onChange={(e) => setLink(e.target.value)}
              style={{ marginTop: "20px" }}
            />
          ) : (
            link && (
              <a
                href={link}
                type="button"
                className="btn btn-danger"
                target="_blank"
                rel="noopener noreferrer nofollow"
                style={{ marginTop: "20px" }}
              >
                {linkTitle}
              </a>
            )
          )}

          {updateMode && (
            <>
              <button
                className="singlePostButton"
                onClick={() => {
                  confirmAlert({
                    message: "Do you really want to update this post ?",
                    buttons: [
                      {
                        label: "Yes",
                        onClick: () => handleUpdate(),
                      },
                      {
                        label: "No",
                      },
                    ],
                  });
                }}
              >
                Update
              </button>

              <button
                className="singlePostButton"
                onClick={() => {
                  confirmAlert({
                    message: "Do you really want to revalidate this post ?",
                    buttons: [
                      {
                        label: "Yes",
                        onClick: () => Revalidate(),
                      },
                      {
                        label: "No",
                      },
                    ],
                  });
                }}
              >
                Revalidate
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default SinglePost;
