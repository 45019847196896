import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Context } from "../../Context/Context";
import { useLocation } from "react-router-dom";
import "./QNASinglePost.css";
import QuestionAnswer from "./QuestionAnswer/QuestionAnswer";

function QNASinglePost(props) {
  const [data, setData] = useState({});
  const [load, setLoad] = useState(true);
  const { user } = useContext(Context);
  const location = useLocation();
  const slug = location.pathname.split("/")[2];
  const [updateMode, setUpdateMode] = useState(false);

  useEffect(() => {
    setUpdateMode(false);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const getData = async () => {
      setLoad(true);
      props.setProgress(20);
      const URL = process.env.REACT_APP_QNA_BACKEND_URL;
      const res = await axios.get(`${URL}/api/questions/slug?slug=` + slug);

      console.log(res.data)
      props.setProgress(60);
      document.title = res?.data?.title;
      setData(res?.data);

      setLoad(false);
      props.setProgress(100);
    };
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  const updateData = async () => {
    setLoad(true);
    props.setProgress(20);
    const URL = process.env.REACT_APP_QNA_BACKEND_URL;
    const res = await axios.put(`${URL}/api/questions`, data);

    props.setProgress(60);
    document.title = res?.data?.title;
    setData(res?.data);
    setUpdateMode(false);
    setLoad(false);
    props.setProgress(100);
  };

  const handleDelete = async () => {
    setLoad(true);
    props.setProgress(20);
    const URL = process.env.REACT_APP_QNA_BACKEND_URL;
    const res = await axios.delete(`${URL}/api/questions/` + data.id);

    props.setProgress(60);
    alert(res.data.message);
    setUpdateMode(false);
    setLoad(false);
    props.setProgress(100);
    window.location.replace("/");
  };

  return (
    <div className="singlePost">
      {load ? (
        <div className="spinner-border text-primary loadingIcon" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <div className="singlePostWrapper">
          {!updateMode ? (
            data?.imageUrl && (
              <img
                className="singlePostImg"
                src={data?.imageUrl}
                alt={data?.title}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "";
                  e.target.alt = "";
                  e.target.style.display = "none";
                }}
              />
            )
          ) : (
            <input
              type="text"
              className="singlePostTitleInput"
              placeholder={data?.imageUrl}
              value={data.imageUrl}
              onChange={(e) =>
                setData((prev) => ({ ...prev, imageUrl: e.target.value }))
              }
            />
          )}

          {updateMode ? (
            <input
              type="text"
              value={data?.title}
              className="singlePostTitleInput"
              autoFocus
              onChange={(e) =>
                setData((prev) => ({ ...prev, title: e.target.value }))
              }
            ></input>
          ) : (
            <h1 className="singlePostTitle">
              {data?.title}
              <div>
                {(user?.username || user?.role === "admin") && (
                  <div className="singlePostEdit">
                    <i
                      className="singlePostIcon fa-solid fa-pen-to-square"
                      onClick={() => setUpdateMode(true)}
                    ></i>
                    <i
                      className="singlePostIcon fa-solid fa-trash"
                      onClick={() => {
                        confirmAlert({
                          message: "Do you really want to delete this post ?",
                          buttons: [
                            {
                              label: "Yes",
                              onClick: () => handleDelete(),
                            },
                            {
                              label: "No",
                            },
                          ],
                        });
                      }}
                    ></i>
                  </div>
                )}
              </div>
            </h1>
          )}
          {!updateMode ? (
            <span className="tag-container">
              {data?.tags.map((tag, index) => (
                <span key={index} className="tag">
                  {tag}
                </span>
              ))}
            </span>
          ) : (
            <input
              type="text"
              className="singlePostTitleInput"
              value={data?.tags}
              placeholder="Tags"
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  tags: e.target.value.split(","),
                }))
              }
            />
          )}
          {updateMode && (
            <div className="d-flex justify-content-end">
              <button
                className="singlePostButton m-2"
                onClick={() => {
                  setUpdateMode(false);
                }}
              >
                Cancel
              </button>
              <button
                className="singlePostButton m-2"
                onClick={() => {
                  confirmAlert({
                    message: "Do you really want to update this post ?",
                    buttons: [
                      {
                        label: "Yes",
                        onClick: () => updateData(),
                      },
                      {
                        label: "No",
                      },
                    ],
                  });
                }}
              >
                Update
              </button>
            </div>
          )}

          {!updateMode
            ? data?.questionsAndAnswer.map((qna, i) => {
                return (
                  <QuestionAnswer
                    question={qna?.Question}
                    answer={qna?.Answer}
                    key={i}
                  />
                );
              })
            : data?.questionsAndAnswer.map((qna, i) => {
                return (
                  <div key={i} className="question-answer-container m-3">
                    <div className="question">
                      <label>Question</label>
                      <input
                        type="text"
                        value={qna.Question}
                        className="singlePostTitleInput"
                        onChange={(e) => {
                          const updatedData = { ...data };
                          updatedData.questionsAndAnswer[i].Question =
                            e.target.value;
                          setData(updatedData);
                        }}
                      ></input>
                    </div>
                    <div className="answer">
                      <label>
                        <b>Answer</b>
                      </label>
                      <input
                        type="text"
                        value={qna.Answer}
                        className="singlePostTitleInput"
                        onChange={(e) => {
                          const updatedData = { ...data };
                          updatedData.questionsAndAnswer[i].Answer =
                            e.target.value;
                          setData(updatedData);
                        }}
                      ></input>
                    </div>
                  </div>
                );
              })}
        </div>
      )}
    </div>
  );
}

export default QNASinglePost;
