import axios from "axios";
import React, { Fragment, useContext, useMemo } from "react";
import { Context } from "../../Context/Context";
import "./AddBlogs.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";

function AddBlog() {
  let navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [category, setCategory] = useState("");
  const [linkTitle, setLinkTitle] = useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const { user } = useContext(Context);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newPost = {
      username: user.username,
      slug: title.replace(/[^a-zA-Z0-9]+/gi, "-"),
      title,
      desc,
      categories: category,
      link: link,
      linkTitle: linkTitle,
      photo: image,
    };

    if (file) {
      const data = new FormData();
      data.append("file", file);
      data.append("upload_preset", "osgvldv1");
      try {
        const response = await axios.post(
          "https://api.cloudinary.com/v1_1/dp9xuxsug/image/upload",
          data
        );
        newPost.photo = response.data["secure_url"];
      } catch (error) {
        alert(error);
        return;
      }
    }

    e.preventDefault();

    try {
      let authtoken = localStorage.getItem("auth-token");
      const res = await axios.post(
        "https://api-anlakshya.onrender.com/api/posts",
        newPost,
        {
          headers: {
            "auth-token": authtoken,
            "x-window-location": "anucodes.com",
          },
        }
      );
      alert("Blog has been published");
      navigate(`/post/${res.data._id}`);
    } catch (error) {
      alert("error");
    }
  };

  const modules = useMemo(
    () => ({
      syntax: { highlight: (text) => hljs.highlightAuto(text).value },
      toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
        ["link", "image", "video"],
        ["clean"],
      ],
    }),
    []
  );

  return (
    <>
      <div className="write">
        {file && (
          <Fragment>
            <img
              className="writeImg"
              src={URL.createObjectURL(file)}
              alt=""
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "";
                e.target.alt = "";
                e.target.style.display = "none";
              }}
            />
            <i
              className="deleteIcon fa-solid fa-trash"
              onClick={() => {
                setFile(null);
              }}
            ></i>
          </Fragment>
        )}

        <form className="writeForm" onSubmit={handleSubmit}>
          <div className="writeFormGroup">
            <label htmlFor="fileInput">
              <i className="writeIcon fa-solid fa-plus"></i>
            </label>
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={(e) => setFile(e.target.files[0])}
            />
            <input
              type="text"
              placeholder="Enter a Attractive title"
              className="writeInput"
              autoFocus={true}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="writeFormGroup Cats">
            <label htmlFor="category">Category &nbsp; &nbsp;</label>
            <select
              name="category"
              className="category_dropdown"
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">Select</option>
              <option value="technical">Technical Blogs</option>
              <option value="jobs">Jobs</option>
              <option value="wipro">Wipro</option>
              <option value="infosys">Infosys</option>
              <option value="tcs">TCS</option>
              <option value="interview">Interview</option>
              <option value="capgemini">Capgemini</option>
              <option value="Cognizant">Cognizant</option>
              <option value="project">Project</option>
              <option value="other">Other</option>
            </select>
            <span className="or"> OR </span>
          </div>
          {
            <div className="writeLinkGroup">
              <input
                type="text"
                placeholder="Create a New category"
                className="writeInput"
                autoFocus={true}
                onChange={(e) => setCategory(e.target.value)}
              />
            </div>
          }
          {file == null && (
            <div className="writeFormGroup ">
              <input
                type="text"
                placeholder="Enter the complete url of the image"
                className="writeInput"
                autoFocus={true}
                onChange={(e) => setImage(e.target.value)}
              />
            </div>
          )}
          <div className="ql-snow">
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{ __html: desc }}
              onCopy={(e) => {
                e.preventDefault();
              }}
            ></div>
          </div>

          <div className="writeFormGroup">
            <ReactQuill
              className="quillEditor"
              theme="snow"
              modules={modules}
              placeholder="Tell your story..."
              value={desc}
              onChange={setDesc}
            />
          </div>
          <div className="writeLinkGroup mt-5">
            <div className="linkTitle">
              <input
                type="text"
                placeholder="Link Heading Message"
                className="writeInput"
                onChange={(e) => setLinkTitle(e.target.value)}
              />
            </div>
            <div className="link">
              <input
                type="text"
                placeholder="Please Paste the Link"
                className="writeInput"
                onChange={(e) => setLink(e.target.value)}
              />
            </div>
          </div>
          <button className="button writeSubmit" type="submit">
            Publish
          </button>
        </form>
      </div>
    </>
  );
}

export default AddBlog;
