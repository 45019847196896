import React, { useState } from "react";
import "./QuestionAnswer.css";

const QuestionAnswer = ({ question, answer }) => {
  const [showAnswer, setShowAnswer] = useState(false);

  const toggleAnswer = () => {
    setShowAnswer(!showAnswer);
  };

  return (
    <div className="question-answer-container">
      <div className="question">Question : {question}</div>
      {showAnswer && <div className="answer">Answer : {answer}</div>}
      <button onClick={toggleAnswer} className="show-answer-button">
        {showAnswer ? "Hide Answer" : "Show Answer"}
      </button>
    </div>
  );
};

export default QuestionAnswer;
