import React, { useState } from "react";
import "./QNAFileUpload.css";
import * as XLSX from "xlsx";

function QNAFileUpload() {
  const [data, setData] = useState({ title: "", image: "", tags: [] });
  const [file, setFile] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(e.target.files[0]);

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        setTableData(jsonData);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const handleUpload = async () => {
    setLoading(true);
    try {
      if (!file) {
        alert("Please select a file.");
        return;
      }
      if (data.title === "") {
        alert("Title cannot be empty");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("title", data.title);
      formData.append("tags", data.tags);
      formData.append("imageUrl", data.image);
      const response = await fetch(
        `${process.env.REACT_APP_QNA_BACKEND_URL}/api/questions/upload`,
        {
          method: "POST",
          body: formData,
        }
      );
      const res = await response.json();
      console.log(res);
      if (res) {
        window.location.replace("/qna/" + res.data.slug);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="QNA-container container">
      <div className="QNA-Form">
        <input
          type="text"
          placeholder="Enter a Attractive title"
          className="writeInput"
          autoFocus={true}
          onChange={(e) =>
            setData((prevData) => ({
              ...prevData,
              title: e.target.value,
            }))
          }
        />
        <input
          type="text"
          placeholder="Enter a Image URL"
          className="writeInput"
          onChange={(e) =>
            setData((prevData) => ({
              ...prevData,
              image: e.target.value,
            }))
          }
        />
        <input
          type="text"
          placeholder="Enter the Tags (Commoma Separated)"
          className="writeInput"
          onChange={(e) =>
            setData((prevData) => ({
              ...prevData,
              tags: [e.target.value.split(",")],
            }))
          }
        />
      </div>
      <div className="QNA-container-header">
        <input type="file" onChange={handleFileChange} />
        <button
          className="button upload-button"
          onClick={!loading && handleUpload}
        >
          {loading ? (
            <div
              className="spinner-border sm"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            "Upload"
          )}
        </button>
      </div>

      {tableData.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>SR NO</th>
              <th>QUESTION</th>
              <th>ANSWER</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>{rowIndex + 1}</td>
                {Object.values(row).map((value, colIndex) => (
                  <td key={colIndex}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default QNAFileUpload;
