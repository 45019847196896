import React from 'react'
import "./single.css"
import SinglePost from '../SinglePost/singlePost';
import LatestPost from '../Components/LatestPost/LatestPost';
import Footer from '../Footer/Footer';
import Comment from '../Components/Comment/Comment';

function Single(props) {
  
  return (<>
    <div className='container'>
      <div className='row'>
        <div className='col-9'>
          <SinglePost setProgress={props.setProgress}></SinglePost>
          
          
        </div>
        <div className='col-3'>
          <div>
            <LatestPost />
          </div>
          <div className='mt-4'>
            <Comment />
           
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </>
  )
}

export default Single