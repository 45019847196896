import React from "react";
import "./post.css";
import { Link } from "react-router-dom";
function Post({ post }) {
  return (
    <div className="post">
      {
        <img className="postImg" src={post?.photo || post?.imageUrl || "https://res.cloudinary.com/dp9xuxsug/image/upload/v1685122667/img1_bxng1c.jpg"} alt="anlakshya" onError={(e) => {
          e.target.onerror = null;
          e.target.src = "";
          e.target.alt = "";
          e.target.style.display = "none";
        }} />
      }
      <div className="postInfo">
        <div className="postCats">
          <span className="postCat"> {post?.categories}</span>
        </div>
        <Link
          to={
            post?._id
              ? `/post/${post?.title?.replace(/[^a-zA-Z0-9]+/gi, "-")}-${post?._id
              }`
              : `/qna/${post?.slug}`
          }
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <h3 className="postTitle">{post?.title}</h3>{" "}
        </Link>
        {post?.createdAt && (
          <span className="postDate">
            {new Date(post?.createdAt)?.toDateString()}
          </span>
        )}
      </div>
      <p
        className="postDesc"
        dangerouslySetInnerHTML={{
          __html: post.desc?.split(" ").slice(0, 40).join(" "),
        }}
      />
      {post._id && (
        <Link
          to={`/post/${post?.title?.replace(/[^a-zA-Z0-9]+/gi, "-")}-${post._id
            }`}
          style={{ textDecoration: "none", color: "red" }}
        >
          <span>Read More...</span>{" "}
        </Link>
      )}
    </div>
  );
}

export default Post;
