import React, { useContext } from "react";
import "./login.css";
import { useRef, useState } from "react";
import { Context } from "../Context/Context";
import axios from "axios";


function Login() {
  const userRef = useRef();
  const passwordRef = useRef();
  const { dispatch, isFetching } = useContext(Context);
  const [success, setSuccess] = useState(false);
  const headers = {
    "x-window-location" : "anucodes.com"
}


  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post(
        "https://api-anlakshya.onrender.com/api/auth/login",
        {
          email: userRef.current.value,
          password: passwordRef.current.value,
        },
        {headers}
      );

      const { authtoken } = res.data;
      localStorage.setItem("auth-token", authtoken);
      setSuccess(false);

      dispatch({ type: "LOGIN_SUCCESS", payload: res.data.others });
    } catch (err) {
      setSuccess(true);

      dispatch({ type: "LOGIN_FAILURE" });
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    }
  };

  return (
    <div className="login">
      <div className="loginContainer">
        <span className="loginTitle">Login</span>
        <form className="loginForm" onSubmit={handleSubmit}>
          <label>Email</label>
          <input
            type="email"
            className="loginInput"
            placeholder="Enter Your Username..."
            ref={userRef}
          />
          <label>Password</label>
          <input
            type="password"
            className=" loginInput"
            placeholder="Enter Your password..."
            ref={passwordRef}
          />
          <button className="loginButton" type="submit" disabled={isFetching}>
            <i className="fa-solid fa-right-to-bracket fa-fade"></i> Login
          </button>
          {success && (
            <span
              style={{ color: "red", marginTop: "10px", alignSelf: "center" }}
            >
              Invalid credentials...
            </span>
          )}
        </form>
      </div>
    </div>
  );
}

export default Login;
